import request from '@/utils/request'

export function login (data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

export function userList (data) {
    return request({
        url: '/home/userList',
        method: 'post',
        data
    })
}

export function bill (data) {
    return request({
        url: '/home/bill',
        method: 'post',
        data
    })
}

export function getBillList (data) {
    return request({
        url: '/home/getBillList',
        method: 'post',
        data
    })
}

export function updateUserInfo (data) {
    return request({
        url: '/home/updateUserInfo',
        method: 'post',
        data
    })
}

export function getNotice (data) {
    return request({
        url: '/home/getNotice',
        method: 'post',
        data
    })
}

export function setNotice (data) {
    return request({
        url: '/home/setNotice',
        method: 'post',
        data
    })
}

export function billDel (data) {
    return request({
        url: '/home/billDel',
        method: 'post',
        data
    })
}

export function updateBill (data) {
    return request({
        url: '/home/updateBill',
        method: 'post',
        data
    })
}